import './App.css';
import React, { Component } from 'react';
import 'react-in-viewport';
import handleViewport from 'react-in-viewport';
import Block from './Block'
import Navbar from './Navbar'
import platt from './images/uwplatt.svg'
import ds from './images/direct-supply.svg'
import { isIOS, isMobileOnly } from 'react-device-detect'

const ViewportBlock = handleViewport(Block);

export default class App extends Component{

  constructor() {
    super();
    this.state = {
      selected:{
        name: true,
        core: false,
        work: false,
        edu: false,
      },
      entered: 1,
    }
  }

  onEnter = (id) => {
    this.setState({
      entered: id
    })
  }

  onLeave = (id) => {
    if(id !== this.state.entered){
      this.setState({
        selected: {
          name: 1 === this.state.entered,
          core: 2 === this.state.entered,
          work: 3 === this.state.entered,
          edu: 4 === this.state.entered
        }
      })
    }
  }

  render(){
    const scroll = (id) => {
      document.getElementById(id).scrollIntoView(true);
      window.scrollBy(0, -250)
      this.setState({
        selected: {
          name: 1 === id,
          core: 2 === id,
          work: 3 === id,
          edu: 4 === id
        },
        entered: id
      })
    };

    return (
      <div className="background">
        <Navbar items={this.state.selected} scroll={scroll}></Navbar>
        <div className="content">
          <ViewportBlock id="1" className="title show" onEnterViewport={() => this.onEnter(1)} onLeaveViewport={() => this.onLeave(1)}>
            <div className="title1 brand">Mitch Vance</div>
            <div className="title2">Software Engineer</div>
          </ViewportBlock>
          <ViewportBlock id="2" className="block" onEnterViewport={() => this.onEnter(2)} onLeaveViewport={() => this.onLeave(2)}>
            <div className="contentTitle">Core Competencies</div>
              <div>C#</div>
              <div>Microsoft SQL</div>
              <div>Postgres</div>
              <div>Javascript</div>
              <div>RESTful Web API</div>
              <div>AngularJS</div>
              <div>Java</div>
              <div>HTML</div>
              <div>React</div>
              <div>Visual Studio</div>
              <div>Visual Studio Code</div>
              <div>Git</div>
              <div>Team Foundation Server</div>
              <div>Target Process</div>
          </ViewportBlock>
          <ViewportBlock id="3" className="block" onEnterViewport={() => this.onEnter(3)} onLeaveViewport={() => this.onLeave(3)}>
            <div className="contentTitle">Work Experience</div>
            <img width="240" height="120" src={ds} alt=""></img>
            <div>Software Engineer Intern</div>
            <div>Direct Supply Inc (2018 - present)</div>
            <br/>
            <div className="contentSubTitle">Projects</div>
            <div>DSSI/Ecommerce</div>
            <div>OGM3</div>
            <div>Price Management</div>
          </ViewportBlock>
          <ViewportBlock id="4" className="block bottom" onEnterViewport={() => this.onEnter(4)} onLeaveViewport={() => this.onLeave(4)}>
            <div className="contentTitle">Education</div>
            <img className={isMobileOnly ? "mobileImage" : "image"} src={platt} alt=""></img>
            <div>Major: B.S. Software Engineering</div>
            <div>Expected graduation: Spring 2021</div>
          </ViewportBlock>
        </div>
      </div>
    );
  }
  
}

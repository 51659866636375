import React, { Component } from 'react';
import './App.css';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import {faEnvelope, faFilePdf} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {isMobile} from 'react-device-detect'

export default class Navbar extends Component {
    constructor(){
        super();
        this.state = {
            clicked: false
        }
    }
    render(){
        const {scroll, items} = this.props;
        if(isMobile){
            return(
                <span className="navBar scrolled">
                    <span className="mobileHeader" hidden={this.state.clicked}>
                        <span className="brand " onClick={() => scroll(1)}>Mitch Vance</span>
                        <span>
                            <a className="icon" href="mailto:mitchell117@live.com">
                                <FontAwesomeIcon icon={faEnvelope} color='darkcyan'/>
                            </a>
                            <a className="icon" href="https://s3.amazonaws.com/mitchellvance.com/Mitch+Vance.pdf" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFilePdf} color='darkcyan'/>
                            </a>
                            <a className="icon" href="https://www.linkedin.com/in/mitch-vance-104740b7/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} color='darkcyan'/>
                            </a>
                        </span>
                    </span>
                </span>
              );
        }
        return(
          <header className="navBar scrolled">
            <div className={"navItem brand " + (items.name ? "selected" : "notSelected")} onClick={() => scroll(1)}>Mitch Vance</div>
            <div className={"navItem " + (items.core ? "selected" : "notSelected")} onClick={() => scroll(2)}>Core Competencies</div>
            <div className={"navItem " + (items.work ? "selected" : "notSelected")} onClick={() => scroll(3)}>Work Experience</div>
            <div className={"navItem " + (items.edu ? "selected" : "notSelected")} onClick={() => scroll(4)}>Education</div>
            <div>
              <a href="mailto:mitchell117@live.com"  style={{margin: 10}}>
                <FontAwesomeIcon icon={faEnvelope} color='darkcyan'/>
              </a>
              <a href="https://s3.amazonaws.com/mitchellvance.com/Mitch+Vance.pdf" target="_blank" rel="noopener noreferrer" style={{margin: 10}}>
                <FontAwesomeIcon icon={faFilePdf} color='darkcyan'/>
              </a>
              <a href="https://www.linkedin.com/in/mitch-vance-104740b7/" target="_blank" rel="noopener noreferrer" style={{margin: 10}}>
                <FontAwesomeIcon icon={faLinkedin} color='darkcyan'/>
              </a>
            </div>
        </header>
        );
    }
}
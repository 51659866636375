import React, {Component} from 'react'
import './App.css';

export default class Block extends Component {
  render(){
    const {id,className} = this.props;
    return(
      <div id={id} className={className}>
        {this.props.children}
      </div>
    );
  }
}

{/* <div className="Block show work">
            <div className="navItem">Work Experience</div>
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
          </div> */}